<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="ms-title">Loongcheer</div>
      <el-form :model="param" :rules="rules" ref="login" label-width="0px" class="ms-content">
        <el-form-item prop="username">
          <el-input v-model="param.username" placeholder="username">
            <template slot="prepend"><i class="el-icon-user"></i></template>
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input type="password" placeholder="password" v-model="param.password" @keyup.enter.native="submitForm()">
            <template slot="prepend"><i class="el-icon-key"></i></template>
          </el-input>
        </el-form-item>
        <el-radio v-model="radio" @change="danxuan" style="color: gray; margin-top: 0px; display: block">remember me?</el-radio>
        <div class="login-btn" style="margin-top: 10px">
          <el-button type="primary" @click="submitForm()">Login</el-button>
        </div>
        <p style="margin-left: 60px" class="login-tips">
          New to site? <el-button @click="dialogFormVisible = true" type="text">Create Account</el-button>
        </p>
      </el-form>
      <!-- 新注册用户弹窗 -->
      <el-dialog style="width: 800px; margin: 0px auto" title="Register" :visible.sync="dialogFormVisible">
        <el-form :model="add" :rules="rules2" ref="ruleForm2">
          <!-- 用户名 -->
          <el-form-item label="Name:" prop="adduser">
            <el-input v-model="add.adduser" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Email:" prop="addyouxiang">
            <el-input v-model="add.addyouxiang" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Password:" prop="addp">
            <el-input v-model="add.addp" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item label="Confirm Password:" prop="addp2">
            <el-input v-model="add.addp2" autocomplete="off" show-password></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="" type="primary" @click="addUser('ruleForm2')">register</el-button>
            <p style="color: gray; float: right" class="login-tips">
              Already a member ? <el-button @click="dialogFormVisible = false" type="text">Log in</el-button>
            </p>
          </el-form-item>
        </el-form>
        <!-- 弹窗下的按钮 -->
        <!-- <div style="display:flex;justify-content:center;align-item:center;width:100%;">
                <el-button style="display:block;margin:0 auto;" type="primary" @click="addUser('ruleForm2')">register</el-button>
                <p style="color:gray;margin-left:60px;" class="login-tips">Already a member ? <el-button @click="dialogFormVisible = false" type="text">Log in</el-button></p>
              </div> -->
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
export default {
  data: function () {
    var checkEmail = (rule, value, callback) => {
      const regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!regEmail.test(value)) {
        callback(new Error('请输入合法的邮箱格式'));
      }

      //邮箱检测
      this.$http
        .get('emails/' + this.add.addyouxiang + '/count/')
        .then(function (res) {
          if (res.data.code == 200) {
            if (res.data.count == 1) {
              callback(new Error('该邮箱已被注册'));
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    };
    var checkName = (rule, value, callback) => {
      //用户名检测
      this.$http
        .get('usernames/' + this.add.adduser + '/count/')
        .then(function (res) {
          if (res.data.code == 200) {
            if (res.data.count == 1) {
              callback(new Error('用户名已被注册'));
            }
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    };
    //二次密码检测
    var checkP2 = (rule, value, callback) => {
      if (this.add.addp != this.add.addp2) {
        callback(new Error('两次密码输入不一致'));
      }
    };
    return {
      jizhu: '',
      radio: '1',
      token: '',
      dialogFormVisible: false,
      param: {
        username: localStorage.getItem('ms_username'),
        password: localStorage.getItem('ms_password')
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }]
      },
      add: {
        adduser: '',
        addyouxiang: '',
        addp: '',
        addp2: ''
      },
      rules2: {
        adduser: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          { validator: checkName, trigger: 'blur' }
        ],
        addyouxiang: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          { validator: checkEmail, trigger: 'blur' }
        ],
        addp: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '至少输入6位', trigger: 'blur' }
        ],
        addp2: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { min: 6, message: '至少输入6位', trigger: 'blur' },
          { validator: checkP2, trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    danxuan() {
      this.jizhu = '1';
    },
    submitForm() {
      if (this.param.username != '' && this.param.password != '') {
        Vue.http.options.emulateJSON = true;
        this.$http
          .post('login/', {
            username: this.param.username,
            password: this.param.password
          })
          .then((res) => {
            if (res.status == 200) {
              localStorage.setItem('jwt-token', res.data.token);
              localStorage.setItem('user-group', res.data.groups);
              localStorage.setItem('ms_username', res.data.username);
              localStorage.setItem('page_list', res.data.page_name);
              if (this.jizhu == '1') {
                localStorage.setItem('ms_password', this.param.password);
              }
              alert('登录成功，点击确定进入系统！');
              this.$router.push('/');
            }
          })
          .catch((e) => {
            alert('密码不正确或用户不存在');
          });
      }
    },
    addUser(ruleForm2) {
      this.$refs[ruleForm2].validate((valid) => {});
      if (
        this.add.adduser != '' &&
        this.add.addyouxiang != '' &&
        this.add.addp != '' &&
        this.add.addp2 != '' &&
        this.add.addp == this.add.addp2
      ) {
        Vue.http.options.emulateJSON = true;
        this.$http
          .post('register/', {
            username: this.add.adduser,
            email: this.add.addyouxiang,
            password: this.add.addp,
            password2: this.add.addp2
          })
          .then((res) => {
            alert(res.data.msg);
          })
          .catch((e) => {});
      }
    }
  },
  watch: {}
};
</script>

<style scoped>
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../../assets/img/login-bg.jpg);
  background-size: 100%;
}
.ms-title {
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 350px;
  margin: -190px 0 0 -175px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.3);
  overflow: hidden;
}
.ms-content {
  padding: 30px 30px;
}
.login-btn {
  text-align: center;
}
.login-btn button {
  width: 100%;
  height: 36px;
  margin-bottom: 10px;
}
.login-tips {
  font-size: 12px;
  line-height: 30px;
  color: #fff;
}
</style>